/* eslint-disable no-use-before-define */

import React, { useEffect, useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import ActiveEntryViewer from '../components/gallery/ActiveEntryViewer';
import DatesMenu from '../components/gallery/DatesMenu';
import GalleryEntry from '../components/gallery/GalleryEntry';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import Spinner from '../components/layout/Spinner';

const Gallery = () => {
  const [filteredGalleryItems, setFilteredGalleryItems] = useState([]);
  const [fetchedGalleryItems, setFetchedGalleryItems] = useState();
  const [dateMenuEntries, setDateMenuEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSelection, setActiveSelection] = useState('All');
  const [activeEntry, setActiveEntry] = useState();

  const [fetchData] = useLazyQuery(GET_GALLERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const items = [];
      data.posts.nodes.forEach((node) => {
        if (node.gallery_control.isGallery) {
          items.push(node);
        }
      });
      const newMenuEntries = [];
      items.forEach((item) => {
        const itemDate = new Date(item.date);
        const yearEntry = {
          year: itemDate.getFullYear(),
        };
        const fullDateEntry = {
          monthNumber: itemDate.getMonth(),
          month: itemDate.toLocaleString('default', { month: 'long' }),
          year: itemDate.getFullYear(),
        };
        if (!newMenuEntries.some((e) => e.year === yearEntry.year)) {
          newMenuEntries.push(yearEntry);
        }
        if (!newMenuEntries.some((e) => e.month === fullDateEntry.month && e.year === fullDateEntry.year)) {
          newMenuEntries.push(fullDateEntry);
        }
      });
      const sortedMenuEntries = newMenuEntries.sort((a, b) => {
        if (a.year !== b.year) {
          return b.year - b.year;
        }
        if (a.year === b.year) {
          return b.monthNumber - a.monthNumber;
        }
        return 0;
      });
      setDateMenuEntries(sortedMenuEntries);
      setFilteredGalleryItems(items);
      setFetchedGalleryItems(items);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleGalleryFilter = (entry) => {
    setActiveSelection(entry);
    if (entry === 'All') {
      setFilteredGalleryItems([...fetchedGalleryItems]);
    } else if (entry.monthNumber) {
      setFilteredGalleryItems(
        fetchedGalleryItems.filter((i) => {
          const itemDate = new Date(i.date);
          return itemDate.getMonth() === entry.monthNumber && itemDate.getFullYear() === entry.year;
        }),
      );
    } else {
      setFilteredGalleryItems(
        fetchedGalleryItems.filter((i) => {
          const itemDate = new Date(i.date);
          return itemDate.getFullYear() === entry.year;
        }),
      );
    }
  };

  const renderGalleryContent = () => (
    <>
      <div className={`gallery-subpage ${filteredGalleryItems.length < 1 ? 'empty' : ''}`}>
        {filteredGalleryItems.length < 1 ? (
          <h1>Brak galerii do wyświetlenia...</h1>
        ) : (
          filteredGalleryItems.map((item, index) => (
            <GalleryEntry setActiveEntry={() => setActiveEntry({ ...item, index })} entry={item} />
          ))
        )}
      </div>
      {dateMenuEntries.length > 0 && (
        <DatesMenu
          activeSelection={activeSelection}
          handleGalleryFilter={(entry) => handleGalleryFilter(entry)}
          dateMenuEntries={dateMenuEntries}
        />
      )}
    </>
  );

  const spinnerWrapper = (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
      <Spinner />
    </div>
  );

  const handleGalleryClose = () => {
    setActiveEntry(undefined);
  };

  return (
    <Layout>
      <SEO title="Galeria" />
      {isLoading ? spinnerWrapper : renderGalleryContent()}
      {activeEntry && <ActiveEntryViewer handleGalleryClose={handleGalleryClose} entry={activeEntry} />}
    </Layout>
  );
};

export default Gallery;

const GET_GALLERY = gql`
  query getGallery {
    posts {
      nodes {
        id
        date
        title
        slug
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        gallery_control {
          isGallery
          galleryPreview
          galleryImages {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
